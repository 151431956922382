<template>
  <v-container>
    <app-error :error="error" v-if="error" />
    <app-loader v-if="!forecast || !chartObject"></app-loader>

    <v-row justify="center" align="center">
      <v-col cols="12" justify="center" align="center">
        <v-row>
          <v-col cols="6" align="right">
            <v-btn-toggle v-model="level" v-show="forecast && chartObject">
              <v-btn
                :value="'DAY'"
                :loading="loading"
                elevation="3"
                rounded
                outlined
                class="mr-5"
              >
                {{ $t("subscriptions.forecast.day") }}
              </v-btn>
              <v-btn
                :value="'WEEK'"
                :loading="loading"
                elevation="3"
                rounded
                outlined
                class="mr-5"
              >
                {{ $t("subscriptions.forecast.week") }}
              </v-btn>
              <v-btn
                :value="'MONTH'"
                :loading="loading"
                elevation="3"
                rounded
                outlined
                class="mr-5"
              >
                {{ $t("subscriptions.forecast.month") }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="fromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="from"
                  :label="$t('subscriptions.forecast.from')"
                  prepend-icon="calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  solo
                  :loading="loading"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="from"
                @input="fromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="toMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="to"
                  :label="$t('subscriptions.forecast.to')"
                  prepend-icon="calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  solo
                  :loading="loading"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="to"
                @input="toMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-btn
              elevation="3"
              rounded
              outlined
              class="mr-5 ml-10"
              @click="download"
            >
              download
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div ref="chart" class="chart"></div>
      </v-col>
    </v-row>

    <v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>{{
            $t("subscriptions.forecast.details")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="[
                { text: $t('subscriptions.forecast.date'), value: 'date' },
                {
                  text: $t('subscriptions.forecast.sum'),
                  value: 'sum',
                  align: 'right',
                },
              ]"
              :items="forecast"
              :items-per-page="30"
              class="elevation-1"
            >
              <template v-slot:item.sum="{ item }">
                {{ $n(item.sum, "currency") }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
let google = window.google;

export default {
  data() {
    return {
      error: null,
      loading: false,
      level: "WEEK",
      fromMenu: false,
      from: null,
      toMenu: false,
      to: null,
      forecast: null,
      chartObject: null,
      chartData: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.error = null;

      this.$store
        .dispatch("subscriptions/forecast", {
          level: this.level,
          from: this.from,
          to: this.to,
          max: this.level === "DAY" ? 30 : 12,
        })
        .then((f) => {
          this.$set(this, "forecast", f.data.values);
          this.drawChart();
        })
        .catch((err) => (this.error = err))
        .finally(() => (this.loading = false));
    },
    drawChart() {
      if (!this.chartObject || !this.forecast) return;

      const chartData = this.chartData;
      chartData.removeRows(0, chartData.getNumberOfRows());

      this.forecast.forEach((f) => {
        chartData.addRow([new Date(f.date), f.sum]);
      });

      const options = {
        title: "Forecast",
        width: undefined,
        height: undefined,
        aggregationTarget: "none",
        legend: {
          position: "none",
        },
        vAxis: {
          format: "decimal",
        },
        animation: {
          duration: 500,
          easing: "out",
          startup: true,
        },
      };

      this.chartObject.draw(chartData, options);
    },
    chartLoader() {
      if (window.google && window.google.charts) {
        return Promise.resolve(window.google);
      }

      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = () => resolve(window.google.charts);
        script.src = "https://www.gstatic.com/charts/loader.js";
        document.body.appendChild(script);
      }).then((loader) => {
        return new Promise((res) => {
          loader.load("current", { packages: ["corechart"] });
          loader.setOnLoadCallback(() => res(window.google));
        });
      });
    },
    download() {
      let url = new URL("/api/v1/subscriptions/forecast.csv", document.baseURI);

      if (this.from) {
        url.searchParams.append("from", this.from);
      }

      if (this.to) {
        url.searchParams.append("to", this.to);
      }

      this.$http.get(url).then((r) => {
        let blob = new Blob([r.data], { type: r.headers["content-type"] });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "maximi_forecast.csv";
        link.click();
      });
    },
  },
  watch: {
    forecast: {
      deep: true,
      handler() {
        this.drawChart();
      },
    },
    level() {
      this.fetchData();
    },
    from() {
      if (this.from && this.to) this.fetchData();
    },
    to() {
      if (this.from && this.to) this.fetchData();
    },
  },
  beforeDestroy() {
    if (this.chartObject && typeof this.chartObject.clearChart === "function") {
      this.chartObject.clearChart();
    }
  },
  mounted() {
    this.chartLoader().then((api) => {
      google = api;
      this.chartObject = new google.visualization.LineChart(this.$refs.chart);
      this.chartData = new google.visualization.DataTable();
      this.chartData.addColumn("date", "Date");
      this.chartData.addColumn("number", "Sum");
      this.drawChart();
    });
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  min-height: 450px;
}
</style>
